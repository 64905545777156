import React from "react";
import "./Team.scss";
import Translate from "react-translate-component";
import SectionHeadline from "../../../../UI/SectionHeadline/SectionHeadline";
import SectionUnderText from "../../../../UI/SectionUnderText/SectionUnderText";
import TeamItem from "./TeamItem/TeamItem";
import caresee_team_1 from "../../../../../assets/images/faces/andrea-svrckova@2x.png";
import caresee_team_2 from "../../../../../assets/images/faces/Roman_Kucera.png";
import caresee_team_3 from "../../../../../assets/images/faces/ondrej-buzala@2x.png";
import caresee_team_4 from "../../../../../assets/images/faces/maria-kovalova@2x.png";
import dev_team_1 from "../../../../../assets/images/faces/Jozef_Hrusecky.jpeg";
import dev_team_2 from "../../../../../assets/images/faces/Dominika_Chalupkova.png";
import dev_team_4 from "../../../../../assets/images/faces/Julia_Spevakova.jpeg";
import dev_team_7 from "../../../../../assets/images/faces/Vladimir_Osvald.jpg";
import dev_team_9 from "../../../../../assets/images/faces/Matus_Andrisov@2x.png";
import dev_team_10 from "../../../../../assets/images/faces/200303_Boris_Nanista_01@2x.png";
import dev_team_11 from "../../../../../assets/images/faces/Peter-konecny@2x.png";
import dev_team_12 from "../../../../../assets/images/faces/Magdalena_Tocikova@2x.png";
import ScrollAnimation from "react-animate-on-scroll";
import wave from "./../../../../../assets/images/transition-orange.svg";

const Team = () => {
  return (
    <section className="Team">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 mt-5">
            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
              <SectionHeadline className="dark">
                <Translate content="homepage.about.team.headline_care" />
              </SectionHeadline>
              <SectionUnderText className="dark full-width">
                <Translate content="homepage.about.team.text_care" unsafe />
              </SectionUnderText>
            </ScrollAnimation>
          </div>

          <TeamItem
            headline={<Translate content="homepage.about.team.member_1" />}
            text={<Translate content="homepage.about.team.member_1_pos" />}
            linked="https://www.linkedin.com/in/andrea-%C5%A1vr%C4%8Dkov%C3%A1-285073121/"
            img={caresee_team_1}
          />
          <TeamItem
            headline={<Translate content="homepage.about.team.member_2" />}
            text={<Translate content="homepage.about.team.member_2_pos" />}
            linked="https://www.linkedin.com/in/roman-kucera/"
            img={caresee_team_2}
            delay={100}
          />
          <TeamItem
            headline={<Translate content="homepage.about.team.member_3" />}
            text={<Translate content="homepage.about.team.member_3_pos" />}
            linked="https://www.linkedin.com/in/ondrej-buzala-15b121188/"
            img={caresee_team_3}
            delay={200}
          />
          <TeamItem
            headline={<Translate content="homepage.about.team.member_4" />}
            text={<Translate content="homepage.about.team.member_4_pos" />}
            linked="https://www.linkedin.com/in/m%C3%A1ria-kova%C4%BEov%C3%A1-761b1b72/"
            img={caresee_team_4}
            delay={300}
          />

          <div className="col-12 mt-5">
            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
              <SectionHeadline className="dark">
                <Translate content="homepage.about.team.headline_dev" />
              </SectionHeadline>
              <SectionUnderText className="dark full-width">
                <Translate content="homepage.about.team.text_dev" unsafe />
              </SectionUnderText>
            </ScrollAnimation>
          </div>
          <TeamItem
            headline={<Translate content="homepage.about.team.dev_12" />}
            text={<Translate content="homepage.about.team.dev_12_pos" />}
            linked="https://www.linkedin.com/in/magdalena-tocikova-5296ba30/"
            img={dev_team_12}
            delay={200}
          />
          <TeamItem
            headline={<Translate content="homepage.about.team.dev_2" />}
            text={<Translate content="homepage.about.team.dev_2_pos" />}
            linked="https://www.linkedin.com/in/dominika-chalupov%C3%A1-71934318b/"
            img={dev_team_2}
            delay={100}
          />
          <TeamItem
            headline={<Translate content="homepage.about.team.dev_1" />}
            text={<Translate content="homepage.about.team.dev_1_pos" />}
            img={dev_team_1}
          />
          <TeamItem
            headline={<Translate content="homepage.about.team.dev_4" />}
            text={<Translate content="homepage.about.team.dev_4_pos" />}
            linked="https://www.linkedin.com/in/julia-spevakova-68243548/"
            img={dev_team_4}
            delay={300}
          />
          <TeamItem
            headline={<Translate content="homepage.about.team.dev_7" />}
            text={<Translate content="homepage.about.team.dev_7_pos" />}
            img={dev_team_7}
            delay={200}
          />
          <TeamItem
            headline={<Translate content="homepage.about.team.dev_10" />}
            text={<Translate content="homepage.about.team.dev_10_pos" />}
            linked="https://www.linkedin.com/in/boris-nani%C5%A1ta-51aa321b0/"
            img={dev_team_10}
            delay={200}
          />
          <TeamItem
            headline={<Translate content="homepage.about.team.dev_9" />}
            text={<Translate content="homepage.about.team.dev_9_pos" />}
            linked="https://www.linkedin.com/in/matúš-andrišov-59a219190/"
            img={dev_team_9}
            delay={200}
          />
          <TeamItem
            headline={<Translate content="homepage.about.team.dev_11" />}
            text={<Translate content="homepage.about.team.dev_11_pos" />}
            linked="https://www.linkedin.com/in/peter-kone%C4%8Dn%C3%BD-b0b559178/"
            img={dev_team_11}
            delay={200}
          />

          {/* <TeamItem
            headline={<Translate content="homepage.about.team.dev_3" />}
            text={<Translate content="homepage.about.team.dev_3_pos" />}
            linked="https://www.linkedin.com/in/simon-adrian/"
            img={dev_team_3}
            delay={200}
          /> */}

          {/* <TeamItem
            headline={<Translate content="homepage.about.team.dev_5" />}
            text={<Translate content="homepage.about.team.dev_5_pos" />}
            linked="https://www.linkedin.com/in/tomaszubrik/"
            img={dev_team_5}
          /> */}
          {/* <TeamItem
            headline={<Translate content="homepage.about.team.dev_6" />}
            text={<Translate content="homepage.about.team.dev_6_pos" />}
            linked="https://www.linkedin.com/in/jakub-pastorek-595b24129"
            img={dev_team_6}
            delay={100}
          /> */}

          {/* <TeamItem
            headline={<Translate content="homepage.about.team.dev_8" />}
            text={<Translate content="homepage.about.team.dev_8_pos" />}
            linked="https://www.linkedin.com/in/pavol-papa%C4%8D-67b113b6/"
            img={dev_team_8}
            delay={300}
          /> */}
        </div>
      </div>
      <img src={wave} alt="wave-about" className="cs_wave" />
    </section>
  );
};

export default Team;
