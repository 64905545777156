import React, { useState } from "react";
import { Accordion, Card, Button, useAccordionToggle } from "react-bootstrap";
import Translate from "react-translate-component";
import "./Accordion.scss";
import arrowDown from "./../../../../../../assets/images/chevron.svg";
import arrowUp from "./../../../../../../assets/images/arrow-up.png";

// function ContextAwareToggle({ children, eventKey, callback, current }) {
//   const decoratedOnClick = useAccordionToggle(eventKey, () => {
//     callback && callback(eventKey);
//   });

//   const isCurrentEventKey = current.toString() === eventKey;

//   return (
//     <button
//       type="button"
//       style={{ backgroundColor: "#fff", border: "none" }}
//       onClick={decoratedOnClick}
//     >
//       {children}
//       {isCurrentEventKey ? (
//         <img src={arrowUp} alt="arrow-down" />
//       ) : (
//         <img src={arrowDown} alt="arrow-down" />
//       )}
//     </button>
//   );
// }

// function AccordionQuestions() {
//   const [currentKey, setCurrentKey] = useState("");

//   return (
//     <div className="d-flex flex-row col-12 pl-0 pr-0">
//       <Accordion className="d-flex flex-row col-12 flex-wrap pl-0 pr-0">
//         <div className="col-12 col-lg-6">
//           <Card className="col-12 pl-0 pr-0">
//             <Card.Header>
//               <ContextAwareToggle
//                 eventKey="0"
//                 callback={() => setCurrentKey(0)}
//                 current={currentKey}
//               >
//                 <Accordion.Toggle
//                   as={Button}
//                   variant="link"
//                   eventKey="0"
//                   className="d-flex flex-column flex-md-row questions"
//                 >
//                   <span className="question-text">
//                     <Translate
//                       content="homepage.questions.accordion_question_1"
//                       unsafe={true}
//                     />
//                   </span>
//                 </Accordion.Toggle>
//               </ContextAwareToggle>
//             </Card.Header>
//             <Accordion.Collapse eventKey="0">
//               <Card.Body>
//                 <Translate
//                   content="homepage.questions.accordion_answer_1"
//                   unsafe={true}
//                 />
//               </Card.Body>
//             </Accordion.Collapse>
//           </Card>
//           <Card className="col-12 pl-0 pr-0">
//             <Card.Header>
//               <ContextAwareToggle
//                 eventKey="1"
//                 callback={() => setCurrentKey(1)}
//                 current={currentKey}
//               >
//                 <Accordion.Toggle
//                   as={Button}
//                   variant="link"
//                   className="d-flex flex-column flex-md-row"
//                 >
//                   <span className="question-text">
//                     <Translate
//                       content="homepage.questions.accordion_question_2"
//                       unsafe={true}
//                     />
//                   </span>
//                 </Accordion.Toggle>
//               </ContextAwareToggle>
//             </Card.Header>
//             <Accordion.Collapse eventKey="1">
//               <Card.Body>
//                 <Translate
//                   content="homepage.questions.accordion_answer_2"
//                   unsafe={true}
//                 />
//               </Card.Body>
//             </Accordion.Collapse>
//           </Card>
//           <Card className="col-12 pl-0 pr-0">
//             <Card.Header>
//               <ContextAwareToggle
//                 eventKey="2"
//                 callback={() => setCurrentKey(2)}
//                 current={currentKey}
//               >
//                 <Accordion.Toggle
//                   as={Button}
//                   variant="link"
//                   eventKey="2"
//                   className="d-flex flex-column flex-md-row"
//                 >
//                   <span className="question-text">
//                     <Translate
//                       content="homepage.questions.accordion_question_3"
//                       unsafe={true}
//                     />
//                   </span>
//                 </Accordion.Toggle>
//               </ContextAwareToggle>
//             </Card.Header>
//             <Accordion.Collapse eventKey="2">
//               <Card.Body>
//                 <Translate
//                   content="homepage.questions.accordion_answer_3"
//                   unsafe={true}
//                 />
//               </Card.Body>
//             </Accordion.Collapse>
//           </Card>
//         </div>
//         <div className="col-12 col-lg-6">
//           <Card className="col-12 pl-0 pr-0">
//             <Card.Header>
//               <ContextAwareToggle
//                 eventKey="3"
//                 callback={() => setCurrentKey(3)}
//                 current={currentKey}
//               >
//                 <Accordion.Toggle
//                   as={Button}
//                   variant="link"
//                   eventKey="3"
//                   className="d-flex flex-column flex-md-row"
//                 >
//                   <span className="question-text">
//                     <Translate
//                       content="homepage.questions.accordion_question_4"
//                       unsafe={true}
//                     />
//                   </span>
//                 </Accordion.Toggle>
//               </ContextAwareToggle>
//             </Card.Header>
//             <Accordion.Collapse eventKey="3">
//               <Card.Body>
//                 <Translate
//                   content="homepage.questions.accordion_answer_4"
//                   unsafe={true}
//                 />
//               </Card.Body>
//             </Accordion.Collapse>
//           </Card>
//           <Card className="col-12 pl-0 pr-0">
//             <Card.Header>
//               <ContextAwareToggle
//                 eventKey="4"
//                 callback={() => setCurrentKey(4)}
//                 current={currentKey}
//               >
//                 <Accordion.Toggle
//                   as={Button}
//                   variant="link"
//                   eventKey="4"
//                   className="d-flex flex-column flex-md-row"
//                 >
//                   <span className="question-text">
//                     <Translate
//                       content="homepage.questions.accordion_question_5"
//                       unsafe={true}
//                     />
//                   </span>
//                 </Accordion.Toggle>
//               </ContextAwareToggle>
//             </Card.Header>
//             <Accordion.Collapse eventKey="4">
//               <Card.Body>
//                 <Translate
//                   content="homepage.questions.accordion_answer_5"
//                   unsafe={true}
//                 />
//               </Card.Body>
//             </Accordion.Collapse>
//           </Card>
//           <Card className="col-12 pl-0 pr-0">
//             <Card.Header>
//               <ContextAwareToggle
//                 eventKey="5"
//                 callback={() => setCurrentKey(5)}
//                 current={currentKey}
//               >
//                 <Accordion.Toggle
//                   as={Button}
//                   variant="link"
//                   eventKey="5"
//                   className="d-flex flex-column flex-md-row"
//                 >
//                   <span className="question-text">
//                     <Translate
//                       content="homepage.questions.accordion_question_6"
//                       unsafe={true}
//                     />
//                   </span>
//                 </Accordion.Toggle>
//               </ContextAwareToggle>
//             </Card.Header>
//             <Accordion.Collapse eventKey="5">
//               <Card.Body>
//                 <Translate
//                   content="homepage.questions.accordion_answer_6"
//                   unsafe={true}
//                 />
//               </Card.Body>
//             </Accordion.Collapse>
//           </Card>
//         </div>
//       </Accordion>
//     </div>
//   );
// }

// export default AccordionQuestions;
import FAQ from "./FAQ";

function AccordionQuestions() {
  const [faqs, setfaqs] = useState([
    {
      question: (
        <Translate
          content="homepage.questions.accordion_question_1"
          unsafe={true}
        />
      ),
      answer: (
        <Translate
          content="homepage.questions.accordion_answer_1"
          unsafe={true}
        />
      ),
      open: false,
      number: 0
    },
    {
      question: (
        <Translate
          content="homepage.questions.accordion_question_2"
          unsafe={true}
        />
      ),
      answer: (
        <Translate
          content="homepage.questions.accordion_answer_2"
          unsafe={true}
        />
      ),
      open: false,
      number: 1
    },
    {
      question: (
        <Translate
          content="homepage.questions.accordion_question_3"
          unsafe={true}
        />
      ),
      answer: (
        <Translate
          content="homepage.questions.accordion_answer_3"
          unsafe={true}
        />
      ),
      open: false,
      number: 2
    },
    {
      question: (
        <Translate
          content="homepage.questions.accordion_question_4"
          unsafe={true}
        />
      ),
      answer: (
        <Translate
          content="homepage.questions.accordion_answer_4"
          unsafe={true}
        />
      ),
      open: false,
      number: 3
    },
    {
      question: (
        <Translate
          content="homepage.questions.accordion_question_5"
          unsafe={true}
        />
      ),
      answer: (
        <Translate
          content="homepage.questions.accordion_answer_5"
          unsafe={true}
        />
      ),
      open: false,
      number: 4
    },
    {
      question: (
        <Translate
          content="homepage.questions.accordion_question_6"
          unsafe={true}
        />
      ),
      answer: (
        <Translate
          content="homepage.questions.accordion_answer_6"
          unsafe={true}
        />
      ),
      open: false,
      number: 5
    }
  ]);

  const toggleFAQ = index => {
    setfaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };

  return (
    <>
      <div className="Accordion">
        <div className="faqs">
          {faqs.map((faq, i) => (
            <FAQ faq={faq} index={i} toggleFAQ={toggleFAQ} />
          ))}
        </div>
      </div>
      {/* 
      <div className="Accordion">
        <div className="faqs">
          {" "}
          <FAQ
            open={false}
            question={
              <Translate
                content="homepage.questions.accordion_question_1"
                unsafe={true}
              />
            }
            answer={
              <Translate
                content="homepage.questions.accordion_answer_1"
                unsafe={true}
              />
            }
            index={0}
            toggleFAQ={toggleFAQ}
          />
          <FAQ
            open={false}
            question={
              <Translate
                content="homepage.questions.accordion_question_2"
                unsafe={true}
              />
            }
            answer={
              <Translate
                content="homepage.questions.accordion_answer_2"
                unsafe={true}
              />
            }
            index={1}
            toggleFAQ={toggleFAQ}
          />
          <FAQ
            open={false}
            question={
              <Translate
                content="homepage.questions.accordion_question_3"
                unsafe={true}
              />
            }
            answer={
              <Translate
                content="homepage.questions.accordion_answer_3"
                unsafe={true}
              />
            }
            index={2}
            toggleFAQ={toggleFAQ}
          />
          <FAQ
            open={false}
            question={
              <Translate
                content="homepage.questions.accordion_question_4"
                unsafe={true}
              />
            }
            answer={
              <Translate
                content="homepage.questions.accordion_answer_4"
                unsafe={true}
              />
            }
            index={3}
            toggleFAQ={toggleFAQ}
          />
          <FAQ
            open={false}
            question={
              <Translate
                content="homepage.questions.accordion_question_5"
                unsafe={true}
              />
            }
            answer={
              <Translate
                content="homepage.questions.accordion_answer_5"
                unsafe={true}
              />
            }
            index={4}
            toggleFAQ={toggleFAQ}
          />
          <FAQ
            open={false}
            question={
              <Translate
                content="homepage.questions.accordion_question_6"
                unsafe={true}
              />
            }
            answer={
              <Translate
                content="homepage.questions.accordion_answer_6"
                unsafe={true}
              />
            }
            index={5}
            toggleFAQ={toggleFAQ}
          />
        </div>
      </div> */}
    </>
  );
}

export default AccordionQuestions;
