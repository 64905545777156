import React, { useContext, useState } from "react";
import {
  Accordion,
  Card,
  Button as Btn,
  useAccordionToggle
} from "react-bootstrap";
import { StateContext } from "../../context/state-context-provider";
import { Formik, FieldArray, getIn } from "formik";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from "react-google-recaptcha-v3";
import QFErrorElement from "./question-form-error";
import QuestionFormSchema from "./question-form-rules";
import {
  QFTextInputSC,
  QFSubmitBtnSC,
  QFSuccessMessageSC,
  QFSectionSC,
  QFAccordionSC,
  CHCheckboxInputSC
} from "./question-form.styled";
import { QFCheckboxInputSC } from "./../checkbox-question-form/checkbox-contact-form.stlyed";
import Translate from "react-translate-component";
import SectionHeadline from "./../UI/SectionHeadline/SectionHeadline";
import Button from "./../UI/Button/Button";
import Api from "../../utils/api";
import NLErrorElement from "../newslatter-form/newslatter-form-error";

const QuestionForm = () => {
  function ContextAwareToggle({ children, eventKey, callback, current }) {
    const decoratedOnClick = useAccordionToggle(eventKey, () => {
      callback && callback(eventKey);
    });

    const isCurrentEventKey = current.toString() === eventKey.toString();

    return (
      <button
        type="button"
        style={{
          border: "none",
          backgroundColor: "#fff",
          display: isCurrentEventKey ? "none" : "flex"
        }}
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }

  const [currentKey, setCurrentKey] = useState("0");

  const formInitialValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    merchant_name: "",
    merchant_address: "",
    buildings: [
      {
        name: "",
        address: "",
        law_form: "",
        founder: "",
        num_clients: "",
        num_employees: "",
        email: "",
        /* checkboxes  */
        service_facility_seniors: false,
        service_nursing_home: false,
        service_nursing_facility: false,
        service_specialized_facility: false,
        service_daily_care: false,
        service_day_nursery: false,
        service_social_home: false,
        another_service: ""
      }
    ],
    terms: false,
    newsletter: false
  };

  const {
    formValues,
    setContactSent,
    setCFormOpen,
    contactSent,
    keyCounter,
    setKeyCounter
  } = useContext(StateContext);

  const { executeRecaptcha } = useGoogleReCaptcha();

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={QuestionFormSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        const sendQuestionnaire = async () => {
          const recaptchaToken = await executeRecaptcha("question_form");
          const result = await Api.sendQuestionForm({
            ...values,
            _token: recaptchaToken
          });

          setSubmitting(false);
          resetForm({});

          if (result.status === 200) {
            setContactSent(true);
            setCFormOpen(false);
            window.location.href = "/form-sent";
          }
        };

        sendQuestionnaire();
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValid
      }) => {
        const emailHasError = errors && errors.email && touched.email;
        const firstNameHasError =
          errors && errors.first_name && touched.first_name;
        const lastNameHasError =
          errors && errors.last_name && touched.last_name;
        const phoneHasError = errors && errors.phone && touched.phone;
        const merchantNameHasError =
          errors && errors.merchant_name && touched.merchant_name;
        const merchantAddressHasError =
          errors && errors.merchant_address && touched.merchant_address;
        const newsletterHasError =
          errors && errors.newsletter && touched.newsletter;
        const termsHasError = errors && errors.terms && touched.terms;

        const checkIfIsError = name => {
          const isError = getIn(errors, name) !== undefined;
          const isTouched = getIn(touched, name) !== undefined;

          return isError && isTouched ? true : false;
        };

        return (
          <form onSubmit={handleSubmit}>
            <QFSectionSC className="first-section">
              <SectionHeadline className="dark small left">
                <Translate content="questionaire.contact_info" />
              </SectionHeadline>
              <span className="row fill-content">
                <div className="col-12 col-md-6">
                  <p>
                    <Translate content="questionaire.name" />
                  </p>
                  <QFTextInputSC
                    type="text"
                    name="first_name"
                    filled={values.first_name}
                    hasError={firstNameHasError}
                    placeholder="Zadajte Vaše meno"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.first_name || ""}
                  />
                  {firstNameHasError ? (
                    <QFErrorElement errMsg={errors.first_name} />
                  ) : null}
                </div>
                <div className="col-12 col-md-6">
                  <p>
                    <Translate content="questionaire.surname" />
                  </p>
                  <QFTextInputSC
                    type="text"
                    name="last_name"
                    filled={values.last_name}
                    hasError={lastNameHasError}
                    placeholder="Zadajte Vaše priezvisko"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.last_name || ""}
                  />
                  {lastNameHasError ? (
                    <QFErrorElement errMsg={errors.last_name} />
                  ) : null}
                </div>
                <div className="col-12 col-md-6">
                  <p>
                    <Translate content="questionaire.contact_email_address" />
                  </p>
                  <QFTextInputSC
                    type="email"
                    name="email"
                    filled={values.email}
                    hasError={emailHasError}
                    placeholder="Zadajte Váš email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email || ""}
                  />
                  {emailHasError ? (
                    <QFErrorElement errMsg={errors.email} />
                  ) : null}
                </div>
                <div className="col-12 col-md-6">
                  <p>
                    <Translate content="questionaire.phone" />
                  </p>
                  <QFTextInputSC
                    type="text"
                    name="phone"
                    filled={values.phone}
                    hasError={phoneHasError}
                    placeholder="Zadajte Vaše telefónne číslo v medzinárodnom formáte"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone || ""}
                  />
                  {phoneHasError ? (
                    <QFErrorElement errMsg={errors.phone} />
                  ) : null}
                </div>
              </span>
            </QFSectionSC>
            <QFSectionSC>
              <SectionHeadline className="dark small left">
                <Translate content="questionaire.facility_info" />
              </SectionHeadline>
              <span className="row fill-content">
                <div className="col-12 col-md-6">
                  <p>
                    <Translate content="questionaire.facility_name" />
                  </p>
                  <QFTextInputSC
                    type="text"
                    name="merchant_name"
                    filled={values.merchant_name}
                    hasError={merchantNameHasError}
                    placeholder="Zadajte názov zariadenia"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.merchant_name || ""}
                  />
                  {merchantNameHasError ? (
                    <QFErrorElement errMsg={errors.merchant_name} />
                  ) : null}
                </div>
                <div className="col-12 col-md-6">
                  <p>
                    <Translate content="questionaire.facility_address" />
                  </p>
                  <QFTextInputSC
                    type="text"
                    name="merchant_address"
                    filled={values.merchant_address}
                    hasError={merchantAddressHasError}
                    placeholder="Zadajte adresu"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.merchant_address || ""}
                  />
                  {merchantAddressHasError ? (
                    <QFErrorElement errMsg={errors.merchant_address} />
                  ) : null}
                </div>
              </span>
            </QFSectionSC>

            <QFAccordionSC>
              <Accordion activeKey={currentKey}>
                <FieldArray
                  name="buildings"
                  render={arrayHelpers =>
                    values.buildings
                      ? values.buildings.map((building, i) => (
                          <div key={i}>
                            <Card>
                              <Card.Header>
                                {/* <Accordion.Toggle
                                  as={Btn}
                                  variant="link"
                                  eventKey="0"
                                >
                                  <div className="d-flex flex-column align-items-center align-items-md-start">
                                    <span className="mb-1">
                                      <Translate
                                        content="questionaire.premises_name"
                                        unsafe={true}
                                      />{" "}
                                      {i + 1}
                                    </span>
                                    <span>
                                      <SectionHeadline className="dark x_small left">
                                        {values.buildings[i].name}
                                      </SectionHeadline>
                                    </span>
                                  </div>

                                  <button
                                    type="button"
                                    className="basic_btn is-bigger is-wider bordered dark thin-border d-flex align-items-center"
                                    // onClick={() => onLoginClick()}
                                  >
                                    Upraviť
                                  </button>
                                </Accordion.Toggle> */}
                                <ContextAwareToggle
                                  eventKey={`${i}`}
                                  callback={() => setCurrentKey(`${i}`)}
                                  current={currentKey}
                                >
                                  <Accordion.Toggle
                                    as={Btn}
                                    variant="link"
                                    eventKey="0"
                                    className="d-flex flex-column flex-lg-row"
                                    style={{
                                      textDecoration: "none"
                                    }}
                                  >
                                    <div className="d-flex flex-column align-items-center align-items-lg-start">
                                      <span className="mb-1">
                                        <Translate
                                          content="questionaire.premises_name"
                                          unsafe={true}
                                        />{" "}
                                        {i + 1}
                                      </span>
                                      <span>
                                        <SectionHeadline className="dark x_small left">
                                          {values.buildings[i].name}
                                        </SectionHeadline>
                                      </span>
                                    </div>
                                    <div className="d-flex flex-column flex-sm-row align-items-center">
                                      <button
                                        type="button"
                                        className="basic_btn is-bigger is-wider bordered dark thin-border d-flex align-items-center mr-0 mb-3 mr-sm-3 mb-sm-0"
                                        onClick={() => arrayHelpers.remove(i)}
                                        style={{ width: "190px" }}
                                      >
                                        Vymazať
                                      </button>
                                      <button
                                        type="button"
                                        className="basic_btn is-bigger is-wider black thin-border d-flex align-items-center"
                                        style={{
                                          color: "#fff",
                                          width: "190px"
                                        }}
                                        // onClick={() => onLoginClick()}
                                      >
                                        Upraviť
                                      </button>
                                    </div>
                                  </Accordion.Toggle>
                                </ContextAwareToggle>
                              </Card.Header>
                              <Accordion.Collapse eventKey={`${i}`}>
                                <Card.Body>
                                  <QFSectionSC>
                                    <SectionHeadline className="dark small left">
                                      <Translate content="questionaire.premises_identification" />{" "}
                                      {i + 1}
                                    </SectionHeadline>
                                    <span className="row fill-content">
                                      <div className="col-12 col-md-6">
                                        <p>
                                          <Translate content="questionaire.premises_name" />{" "}
                                          {i + 1}
                                        </p>
                                        <QFTextInputSC
                                          type="text"
                                          name={`buildings[${i}].name`}
                                          placeholder="Zadajte názov prevádzky"
                                          hasError={checkIfIsError(
                                            `buildings[${i}].name`
                                          )}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.buildings[i].name || ""}
                                        />
                                        {checkIfIsError(
                                          `buildings[${i}].name`
                                        ) ? (
                                          <QFErrorElement
                                            errMsg={errors.buildings[i].name}
                                          />
                                        ) : null}
                                      </div>
                                      <div className="col-12 col-md-6">
                                        <p>
                                          <Translate content="questionaire.premises_address" />
                                        </p>

                                        <QFTextInputSC
                                          type="text"
                                          name={`buildings[${i}].address`}
                                          placeholder="Zadajte adresu"
                                          hasError={checkIfIsError(
                                            `buildings[${i}].address`
                                          )}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={
                                            values.buildings[i].address || ""
                                          }
                                        />
                                        {checkIfIsError(
                                          `buildings[${i}].address`
                                        ) ? (
                                          <QFErrorElement
                                            errMsg={errors.buildings[i].address}
                                          />
                                        ) : null}
                                      </div>
                                      <div className="col-12 col-md-6">
                                        <p>
                                          <Translate content="questionaire.legal_form" />
                                        </p>
                                        <QFTextInputSC
                                          type="text"
                                          name={`buildings[${i}].law_form`}
                                          filled={values.buildings.law_form}
                                          hasError={checkIfIsError(
                                            `buildings[${i}].law_form`
                                          )}
                                          placeholder="Zadajte právnu formu"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={
                                            values.buildings[i].law_form || ""
                                          }
                                        />
                                        {checkIfIsError(
                                          `buildings[${i}].law_form`
                                        ) ? (
                                          <QFErrorElement
                                            errMsg={
                                              errors.buildings[i].law_form
                                            }
                                          />
                                        ) : null}
                                      </div>
                                      <div className="col-12 col-md-6">
                                        <p>
                                          <Translate content="questionaire.founder" />
                                        </p>
                                        <QFTextInputSC
                                          type="text"
                                          name={`buildings[${i}].founder`}
                                          hasError={checkIfIsError(
                                            `buildings[${i}].founder`
                                          )}
                                          placeholder="Zadajte zriadovateľa"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={
                                            values.buildings[i].founder || ""
                                          }
                                        />
                                        {checkIfIsError(
                                          `buildings[${i}].founder`
                                        ) ? (
                                          <QFErrorElement
                                            errMsg={errors.buildings[i].founder}
                                          />
                                        ) : null}
                                      </div>
                                      <div className="col-12 col-md-6">
                                        <p>
                                          <Translate content="questionaire.premises_clients_number" />
                                        </p>
                                        <QFTextInputSC
                                          type="text"
                                          name={`buildings[${i}].num_clients`}
                                          hasError={checkIfIsError(
                                            `buildings[${i}].num_clients`
                                          )}
                                          placeholder="Zadajte počet klientov"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={
                                            values.buildings[i].num_clients ||
                                            ""
                                          }
                                        />
                                        {checkIfIsError(
                                          `buildings[${i}].num_clients`
                                        ) ? (
                                          <QFErrorElement
                                            errMsg={
                                              errors.buildings[i].num_clients
                                            }
                                          />
                                        ) : null}
                                      </div>
                                      <div className="col-12 col-md-6">
                                        <p>
                                          <Translate content="questionaire.premises_employees_number" />
                                        </p>
                                        <QFTextInputSC
                                          type="text"
                                          name={`buildings[${i}].num_employees`}
                                          hasError={checkIfIsError(
                                            `buildings[${i}].num_employees`
                                          )}
                                          placeholder="Zadajte počet zamestnancov"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={
                                            values.buildings[i].num_employees ||
                                            ""
                                          }
                                        />
                                        {checkIfIsError(
                                          `buildings[${i}].num_employees`
                                        ) ? (
                                          <QFErrorElement
                                            errMsg={
                                              errors.buildings[i].num_employees
                                            }
                                          />
                                        ) : null}
                                      </div>
                                      <div className="col-12 col-md-6">
                                        <p>
                                          <Translate content="questionaire.contact_email" />
                                        </p>
                                        <QFTextInputSC
                                          type="email"
                                          name={`buildings[${i}].email`}
                                          hasError={checkIfIsError(
                                            `buildings[${i}].email`
                                          )}
                                          placeholder="Zadajte kontaktný email"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={
                                            values.buildings[i].email || ""
                                          }
                                        />
                                        {checkIfIsError(
                                          `buildings[${i}].email`
                                        ) ? (
                                          <QFErrorElement
                                            errMsg={errors.buildings[i].email}
                                          />
                                        ) : null}
                                      </div>
                                    </span>
                                    <SectionHeadline className="dark x_small left mt-5 mb-5">
                                      <Translate content="questionaire.services_types" />
                                    </SectionHeadline>
                                    <span
                                      className="row justify-content-between"
                                      style={{ margin: "0" }}
                                    >
                                      <div className="col-md-6 col-xl-4 row">
                                        <QFCheckboxInputSC
                                          type="checkbox"
                                          hasError={checkIfIsError(
                                            `buildings[${i}].service_facility_seniors`
                                          )}
                                          onBlur={handleBlur}
                                          name={`buildings[${i}].service_facility_seniors`}
                                          value={
                                            values.buildings[i]
                                              .service_facility_seniors || false
                                          }
                                          checked={
                                            values.buildings[i]
                                              .service_facility_seniors || false
                                          }
                                          onChange={handleChange}
                                        />
                                        <Translate
                                          component="p"
                                          content="questionaire.services.facility_for_seniors"
                                          unsafe
                                        />
                                        {checkIfIsError(
                                          `buildings[${i}].service_facility_seniors`
                                        ) ? (
                                          <QFErrorElement
                                            errMsg={
                                              errors.buildings[i]
                                                .service_facility_seniors
                                            }
                                          />
                                        ) : null}
                                      </div>
                                      <div className="col-md-6 col-xl-4 row">
                                        <QFCheckboxInputSC
                                          type="checkbox"
                                          hasError={checkIfIsError(
                                            `buildings[${i}].service_nursing_home`
                                          )}
                                          onBlur={handleBlur}
                                          name={`buildings[${i}].service_nursing_home`}
                                          value={
                                            values.buildings[i]
                                              .service_nursing_home || false
                                          }
                                          checked={
                                            values.buildings[i]
                                              .service_nursing_home || false
                                          }
                                          onChange={handleChange}
                                        />
                                        <Translate
                                          component="p"
                                          content="questionaire.services.nursing_service_at_home"
                                          unsafe
                                        />
                                        {checkIfIsError(
                                          `buildings[${i}].service_nursing_home`
                                        ) ? (
                                          <QFErrorElement
                                            errMsg={
                                              errors.buildings[i]
                                                .service_nursing_home
                                            }
                                          />
                                        ) : null}
                                      </div>
                                      <div className="col-md-6 col-xl-4 row">
                                        <QFCheckboxInputSC
                                          type="checkbox"
                                          hasError={checkIfIsError(
                                            `buildings[${i}].service_nursing_facility`
                                          )}
                                          onBlur={handleBlur}
                                          name={`buildings[${i}].service_nursing_facility`}
                                          value={
                                            values.buildings[i]
                                              .service_nursing_facility || false
                                          }
                                          checked={
                                            values.buildings[i]
                                              .service_nursing_facility || false
                                          }
                                          onChange={handleChange}
                                        />
                                        <Translate
                                          component="p"
                                          content="questionaire.services.nursing_service_facility"
                                          unsafe
                                        />
                                        {checkIfIsError(
                                          `buildings[${i}].service_nursing_facility`
                                        ) ? (
                                          <QFErrorElement
                                            errMsg={
                                              errors.buildings[i]
                                                .service_nursing_facility
                                            }
                                          />
                                        ) : null}
                                      </div>
                                      <div className="col-md-6 col-xl-4 row">
                                        <QFCheckboxInputSC
                                          type="checkbox"
                                          hasError={checkIfIsError(
                                            `buildings[${i}].service_specialized_facility`
                                          )}
                                          onBlur={handleBlur}
                                          name={`buildings[${i}].service_specialized_facility`}
                                          value={
                                            values.buildings[i]
                                              .service_specialized_facility ||
                                            false
                                          }
                                          checked={
                                            values.buildings[i]
                                              .service_specialized_facility ||
                                            false
                                          }
                                          onChange={handleChange}
                                        />
                                        <Translate
                                          component="p"
                                          content="questionaire.services.specialized_facility"
                                          unsafe
                                        />
                                        {checkIfIsError(
                                          `buildings[${i}].service_specialized_facility`
                                        ) ? (
                                          <QFErrorElement
                                            errMsg={
                                              errors.buildings[i]
                                                .service_specialized_facility
                                            }
                                          />
                                        ) : null}
                                      </div>
                                      <div className="col-md-6 col-xl-4 row">
                                        <QFCheckboxInputSC
                                          type="checkbox"
                                          hasError={checkIfIsError(
                                            `buildings[${i}].service_daily_care`
                                          )}
                                          onBlur={handleBlur}
                                          name={`buildings[${i}].service_daily_care`}
                                          value={
                                            values.buildings[i]
                                              .service_daily_care || false
                                          }
                                          checked={
                                            values.buildings[i]
                                              .service_daily_care || false
                                          }
                                          onChange={handleChange}
                                        />
                                        <Translate
                                          component="p"
                                          content="questionaire.services.daily_care_center"
                                          unsafe
                                        />
                                        {checkIfIsError(
                                          `buildings[${i}].service_daily_care`
                                        ) ? (
                                          <QFErrorElement
                                            errMsg={
                                              errors.buildings[i]
                                                .service_daily_care
                                            }
                                          />
                                        ) : null}
                                      </div>
                                      <div className="col-md-6 col-xl-4 row">
                                        <QFCheckboxInputSC
                                          type="checkbox"
                                          hasError={checkIfIsError(
                                            `buildings[${i}].service_day_nursery`
                                          )}
                                          onBlur={handleBlur}
                                          name={`buildings[${i}].service_day_nursery`}
                                          value={
                                            values.buildings[i]
                                              .service_day_nursery || false
                                          }
                                          checked={
                                            values.buildings[i]
                                              .service_day_nursery || false
                                          }
                                          onChange={handleChange}
                                        />
                                        <Translate
                                          component="p"
                                          content="questionaire.services.day_nursery"
                                          unsafe
                                        />
                                        {checkIfIsError(
                                          `buildings[${i}].service_day_nursery`
                                        ) ? (
                                          <QFErrorElement
                                            errMsg={
                                              errors.buildings[i]
                                                .service_day_nursery
                                            }
                                          />
                                        ) : null}
                                      </div>
                                      <div className="col-md-6 col-xl-4 row">
                                        <QFCheckboxInputSC
                                          type="checkbox"
                                          hasError={checkIfIsError(
                                            `buildings[${i}].service_social_home`
                                          )}
                                          onBlur={handleBlur}
                                          name={`buildings[${i}].service_social_home`}
                                          value={
                                            values.buildings[i]
                                              .service_social_home || false
                                          }
                                          checked={
                                            values.buildings[i]
                                              .service_social_home || false
                                          }
                                          onChange={handleChange}
                                        />
                                        <Translate
                                          component="p"
                                          content="questionaire.services.social_services_home"
                                          unsafe
                                        />

                                        {checkIfIsError(
                                          `buildings[${i}].service_social_home`
                                        ) ? (
                                          <QFErrorElement
                                            errMsg={
                                              errors.buildings[i]
                                                .service_social_home
                                            }
                                          />
                                        ) : null}
                                      </div>
                                    </span>
                                    <span className="row fill-content">
                                      <div className="col-12 col-md-6">
                                        <p>
                                          <Translate content="questionaire.services.other_service" />
                                        </p>
                                        <QFTextInputSC
                                          type="text"
                                          name={`buildings[${i}].another_service`}
                                          placeholder="Zadajte inú službu, ktorú poskytujete v zariadení"
                                          hasError={checkIfIsError(
                                            `buildings[${i}].another_service`
                                          )}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={
                                            values.buildings[i]
                                              .another_service || ""
                                          }
                                        />
                                        {checkIfIsError(
                                          `buildings[${i}].another_service`
                                        ) ? (
                                          <QFErrorElement
                                            errMsg={
                                              errors.buildings[i]
                                                .another_service
                                            }
                                          />
                                        ) : null}
                                      </div>
                                    </span>
                                  </QFSectionSC>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                            {/* </Accordion>
                        </QFAccordionSC> */}
                            {values.buildings.length === i + 1 ? (
                              <QFSectionSC>
                                <span className="row fill-content">
                                  <div className="col-12">
                                    <p className="premises mt-0">
                                      <Translate content="questionaire.multiple_premises" />
                                    </p>
                                  </div>
                                  <div className="col-12 d-flex flex-column flex-md-row align-items-center justify-content-md-between mt-3">
                                    {isValid ? (
                                      <Button
                                        type="button"
                                        className="basic_btn is-bigger is-wider bordered dark thin-border mb-3 mb-md-0 btn"
                                        onClick={() => {
                                          setKeyCounter(keyCounter + 1);
                                          setCurrentKey(
                                            `${values.buildings.length}`
                                          );
                                          arrayHelpers.push({
                                            name: "",
                                            address: "",
                                            law_form: "",
                                            founder: "",
                                            num_clients: "",
                                            num_employees: "",
                                            email: "",
                                            service_facility_seniors: false,
                                            service_nursing_home: false,
                                            service_nursing_facility: false,
                                            service_specialized_facility: false,
                                            service_daily_care: false,
                                            service_day_nursery: false,
                                            service_social_home: false,
                                            another_service: ""
                                          });
                                        }}
                                      >
                                        Identifikácia prevádzky č. {i + 2}
                                      </Button>
                                    ) : (
                                      <Button
                                        type="button"
                                        className="basic_btn is-bigger is-wider bordered dark thin-border mb-3 mb-md-0 btn"
                                      >
                                        Identifikácia prevádzky č. {i + 2}
                                      </Button>
                                    )}

                                    {console.log("udaje: ", formValues)}
                                  </div>
                                </span>
                              </QFSectionSC>
                            ) : null}
                          </div>
                        ))
                      : null
                  }
                />
                <QFSectionSC className="last-section">
                  <span className="row fill-content">
                    <div className="col-12 d-flex">
                      <CHCheckboxInputSC
                        type="checkbox"
                        hasError={termsHasError}
                        onBlur={handleBlur}
                        name="terms"
                        value="terms & conditions"
                        onChange={handleChange}
                        checked={values.terms || false}
                      />

                      <Translate
                        component="p"
                        content="homepage.newsletter.consent_text"
                        unsafe
                        className="checkbox-text"
                      />

                      {termsHasError ? (
                        <NLErrorElement errMsg={errors.terms} />
                      ) : null}
                    </div>
                    <div className="col-12 d-flex mt-3">
                      <CHCheckboxInputSC
                        type="checkbox"
                        hasError={newsletterHasError}
                        onBlur={handleBlur}
                        name="newsletter"
                        value="newsletter & conditions"
                        onChange={handleChange}
                        checked={values.newsletter || false}
                      />
                      <Translate
                        component="p"
                        content="homepage.newsletter.small_text"
                        unsafe
                        className="checkbox-text"
                      />
                      {newsletterHasError ? (
                        <NLErrorElement errMsg={errors.newsletter} />
                      ) : null}
                    </div>
                    <div className="col-12">
                      {console.log("udaje: ", formValues)}

                      <QFSubmitBtnSC
                        type="submit"
                        disabled={isSubmitting}
                        isSent={false}
                      >
                        Odoslať formulár
                      </QFSubmitBtnSC>
                    </div>
                  </span>
                </QFSectionSC>
              </Accordion>
            </QFAccordionSC>

            {/* <QFSectionSC className="last-section">
              <span className="row fill-content">
                <div className="col-12">
                  <p className="mt-0">
                    <Translate content="questionaire.multiple_premises" />
                  </p>
                </div>
                <div className="col-12 d-flex justify-content-between mt-3">
                  <Button
                    type="button"
                    className="basic_btn is-bigger is-wider bordered dark thin-border"
                    // onClick={() => setShowAdditionalBuilding(true)}
                    // onClick={() => arrayHelpers.push({})}
                  >
                    Identifikácia prevádzky č.
                  </Button>

                  <QFSubmitBtnSC
                    type="submit"
                    disabled={isSubmitting}
                    isSent={false}
                  >
                    Odoslať formulár
                  </QFSubmitBtnSC>
                </div>
              </span>
            </QFSectionSC> */}

            <span className="row">
              <div className="col-md-12">
                <div className="submit-btn-wrapper">
                  {/* {contactSent ? (
                    <QFSuccessMessageSC>
                      Ďakujeme Vaša správa bola úspešne odoslaná
                    </QFSuccessMessageSC>
                  ) : null} */}
                  {/* <QFSubmitBtnSC
                    type="submit"
                    disabled={isSubmitting}
                    isSent={false}
                  >
                    Odoslať
                  </QFSubmitBtnSC> */}
                </div>
              </div>
            </span>
          </form>
        );
      }}
    </Formik>
  );
};

const WrappedQuestionForm = () => (
  <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
    <QuestionForm />
  </GoogleReCaptchaProvider>
);

export default WrappedQuestionForm;
