import React from "react";
import BannerSection from "./Sections/BannerSection/BannerSection";
import AboutProduct from "./Sections/AboutProduct/AboutProduct";
import Solution from "./Sections/Solution/Solution";
import Questions from "./Sections/Questions/Questions";
import News from "./Sections/News/News";
import Newsletter from "./Sections/Newsletter/Newsletter";
import CSModal from "../../UI/Modal/Modal";
import people01 from "../../../assets/images/01People_novinka@2x.png";
import Translate from "react-translate-component";
import "./Homepage.scss";
class Homepage extends React.Component {
  // state = {
  //   modalShow: false
  // };

  // componentDidMount() {
  //   if (!localStorage.getItem("intro-modal")) {
  //     this.setState({ modalShow: true });
  //   }
  // }

  // modalClose = () => {
  //   localStorage.setItem("intro-modal", true);
  //   this.setState({ modalShow: false });
  // };

  render() {
    return (
      <>
        {/* <CSModal show={this.state.modalShow} onHide={this.modalClose}>
          <img
            src={people01}
            className=".cs_modal_newsimg"
            alt="news"
            style={{ width: "100%" }}
          />

          <Translate
            component="h4"
            className="cs_modal_headline news"
            content="homepage.news-modal.headline"
          />

          <Translate
            component="p"
            className="cs_modal_txt"
            content="homepage.news-modal.text"
            unsafe={true}
          />
        </CSModal> */}
        <div className="tophalf-bg">
          <BannerSection scrollAnchor="top" />
          <AboutProduct scrollAnchor="aboutproduct" />
          <Solution scrollAnchor="solution" />
        </div>
        <div className="bottomhalf-bg">
          <Questions scrollAnchor="questions" />
          {/* <RoadMap /> */}

          {/* <Team /> */}
          <News scrollAnchor="news" />
        </div>
        {/* <Partners scrollAnchor="partners" /> */}
        <Newsletter />
      </>
    );
  }
}
export default Homepage;
