import React from "react";
import { Link } from "react-router-dom";
import "../News/News.scss";
import Translate from "react-translate-component";
import SectionHeadline from "../../../../UI/SectionHeadline/SectionHeadline";
import NewsItem from "./NewsItem/NewsItem";
import BlogsItem from "./BlogsItem/BlogsItem";
import Button from "../../../../UI/Button/Button";
import ScrollAnimation from "react-animate-on-scroll";
import axios from "axios";

class News extends React.Component {
  _isMounted = false;

  state = {
    isToggled: false,
    news: [],
    blogs: []
  };

  componentDidMount() {
    this._isMounted = true;
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/rest/news`)
      .then(res => {
        if (this._isMounted) {
          const news = res.data.items;
          this.setState({ news });
        }
      })
      .catch(error => {
        console.log(error);
      });

    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/rest/blogs`)
      .then(res => {
        if (this._isMounted) {
          const blogs = res.data.items;
          this.setState({ blogs });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    let toggled = this.state.isToggled;
    const { blogs } = this.state;

    return (
      <section id={this.props.scrollAnchor} className="News">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                <SectionHeadline className="dark mt-4">
                  <Translate content="homepage.news.headline_news" />
                </SectionHeadline>
              </ScrollAnimation>
            </div>
            <div className="news_space_placeholder">
              {this.state.news.slice(0, 3).map(item => {
                return (
                  <NewsItem
                    key={item.id}
                    id={item.id}
                    externalUrl={item.externalUrl}
                    headline={item.title}
                    text={item.description}
                    img={item.headingImageURL}
                    date={item.publishedAt}
                  />
                );
              })}
            </div>

            <div className="col-12 blog_headline">
              <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                <SectionHeadline className="dark mt-5">
                  <Translate content="homepage.news.headline_blog" />
                </SectionHeadline>
              </ScrollAnimation>
            </div>
            <div className="blogs_space_placeholder">
              {this.state.blogs.slice(0, 3).map(item => {
                return (
                  <BlogsItem
                    key={item.id}
                    id={item.id}
                    bootstrapClass="col-md-6 col-xl-4 mt-5"
                    externalUrl={item.externalUrl}
                    headline={item.title}
                    text={item.description}
                    img={item.headingImageURL}
                    date={item.publishedAt}
                  />
                );
              })}
            </div>
            {this.state.isToggled ? (
              <div className="blogs_space_placeholder">
                {this.state.blogs.slice(4, 8).map(item => {
                  return (
                    <BlogsItem
                      key={item.id}
                      id={item.id}
                      secondRow
                      bootstrapClass="col-md-6 col-xl-4"
                      externalUrl={item.externalUrl}
                      headline={item.title}
                      text={item.description}
                      img={item.headingImageURL}
                      date={item.publishedAt}
                    />
                  );
                })}
              </div>
            ) : null}

            <div className="col-12 mt-5 mb-5 mb-md-0 text-center">
              <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                <Link to="/blog">
                  <Button
                    className="black is-bigger is-wider"
                    // onClick={() => this.setState({ isToggled: !toggled })}
                  >
                    {" "}
                    {this.state.isToggled ? (
                      <Translate content="homepage.news.btn_menej" />
                    ) : (
                      <Translate content="homepage.news.btn_viac" />
                    )}
                  </Button>
                </Link>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default News;
